import { useLayoutEffect, useState } from 'react';
import { debounce } from '../helpers/debounce';

export const useIsMobileShared = (width: number) => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const resize = () => {
      const _width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsMobile(() => {
        return window.screen.width < width || _width < width;
      });
    };
    resize();
    window.addEventListener('resize', debounce(resize));

    return window.removeEventListener('resize', resize);
  }, []);

  return isMobile;
};

export const useIsWithinWidthRange = (minWidth: number, maxWidth: number) => {
  const [isWithinRange, setIsWithinRange] = useState(false);

  useLayoutEffect(() => {
    const resize = () => {
      const _width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsWithinRange(() => {
        return _width >= minWidth && _width <= maxWidth;
      });
    };
    resize();
    window.addEventListener('resize', debounce(resize));

    return () => window.removeEventListener('resize', resize);
  }, [minWidth, maxWidth]);

  return isWithinRange;
};
